
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91articleSlug_93HN9W5M_EPdCRIL9w_45FyPPNwHJ3mjn0KPcqhp5_45BAI9UMeta } from "/code/pages/[sectionSlug]/[articleSlug].vue?macro=true";
import { default as indexaT9LDUufl6Ta8JDR5Yn0t8KhjsDHsCoyMqwBqetD_45FcMeta } from "/code/pages/[sectionSlug]/index.vue?macro=true";
import { default as _91gallerySlug_93wLcadTvR7q5Hrkm7Bom3QLLXUn3DqlZsWrbSjpVBfEgMeta } from "/code/pages/[sectionSlug]/photos/[gallerySlug].vue?macro=true";
import { default as indexBPDLIX4zlXeHeja7L5OarPeljL0YORAEa4gCQd3doR8Meta } from "/code/pages/index.vue?macro=true";
import { default as newslettersF52M3WUNqAwes9mwlpZFvVCjr7Us9_oxFLvBvfEqtlkMeta } from "/code/pages/newsletters.vue?macro=true";
import { default as previewyHSLMmCIaT9yjsEjQwHOHzGOshMQGcNNZja4ZKe48_45oMeta } from "/code/pages/preview.vue?macro=true";
import { default as searchz9n6cE6T94Hq7t__RzNrbI0r8GGsveOgcAphlNEpcC8Meta } from "/code/pages/search.vue?macro=true";
import { default as sponsoredpRx7lS_45LU_45CdI0JFfRieq7U1_45Dfe6EnmHAEOGGRtI2IMeta } from "/code/pages/sponsored.vue?macro=true";
import { default as _91staffSlug_93CIZbQm3gngsQ69BMQauIMdPADGP_45oaJbW2TeNRiijwMMeta } from "/code/pages/staff/[staffSlug].vue?macro=true";
import { default as _91tagSlug_93p_45oKgv_eX_45CkbWKkEs5nKQqzZCvzqDKwdwD8CD4r7dgMeta } from "/code/pages/tags/[tagSlug].vue?macro=true";
export default [
  {
    name: "sectionSlug-articleSlug",
    path: "/:sectionSlug()/:articleSlug()",
    component: () => import("/code/pages/[sectionSlug]/[articleSlug].vue")
  },
  {
    name: "sectionSlug",
    path: "/:sectionSlug()",
    component: () => import("/code/pages/[sectionSlug]/index.vue")
  },
  {
    name: "sectionSlug-photos-gallerySlug",
    path: "/:sectionSlug()/photos/:gallerySlug()",
    component: () => import("/code/pages/[sectionSlug]/photos/[gallerySlug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/code/pages/index.vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    component: () => import("/code/pages/newsletters.vue")
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/code/pages/preview.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/code/pages/search.vue")
  },
  {
    name: "sponsored",
    path: "/sponsored",
    component: () => import("/code/pages/sponsored.vue")
  },
  {
    name: "staff-staffSlug",
    path: "/staff/:staffSlug()",
    component: () => import("/code/pages/staff/[staffSlug].vue")
  },
  {
    name: "tags-tagSlug",
    path: "/tags/:tagSlug()",
    component: () => import("/code/pages/tags/[tagSlug].vue")
  }
]