import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/code/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/code/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _2_features_zV70V6mn_3ctY11GHbkAgsOFnC_8h_fPsaieFGLqU3c from "/code/plugins/2.features.ts";
import _3_gtm_S1lL5nN5p8sbvq2aEjWQY4IqE_Qzzpb5w18J_Lr1Dtw from "/code/plugins/3.gtm.ts";
import _4_analytics_client__43_aFa99k8TJiZLM7bVQ06sz0kFDEiDpWyJ7ZvJB2k from "/code/plugins/4.analytics.client.ts";
import _5_htlbid_SAky5SZjnO1zLa1E8vVi49qG6bMwmdFezCwI5YoAsXw from "/code/plugins/5.htlbid.ts";
import _6_nativo_Opj17q3btaK_d_NQ1dnEUrRUbMi8rJx7S29Fc1agrzM from "/code/plugins/6.nativo.ts";
import chartbeat_client_S_Z11xnDuJysgGa3C4dIV73wkEqemNXLCimY5lP8z0M from "/code/plugins/chartbeat.client.ts";
import primevue_FwNO_dH40VVjor4TV4eADpOG1SqPJc2e7i7dMMaI9lU from "/code/plugins/primevue.js";
import sentry_client_MJTLNmRW1o4nGDwwmnW8ZOFOAcwoUzwlFoet6n_LfqQ from "/code/plugins/sentry.client.js";
import sfmc_analytics_client_bVc6LCdh0pKyf0PjKliYtCKQ0TpGA0_m_H2edlrahXE from "/code/plugins/sfmc-analytics.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  _2_features_zV70V6mn_3ctY11GHbkAgsOFnC_8h_fPsaieFGLqU3c,
  _3_gtm_S1lL5nN5p8sbvq2aEjWQY4IqE_Qzzpb5w18J_Lr1Dtw,
  _4_analytics_client__43_aFa99k8TJiZLM7bVQ06sz0kFDEiDpWyJ7ZvJB2k,
  _5_htlbid_SAky5SZjnO1zLa1E8vVi49qG6bMwmdFezCwI5YoAsXw,
  _6_nativo_Opj17q3btaK_d_NQ1dnEUrRUbMi8rJx7S29Fc1agrzM,
  chartbeat_client_S_Z11xnDuJysgGa3C4dIV73wkEqemNXLCimY5lP8z0M,
  primevue_FwNO_dH40VVjor4TV4eADpOG1SqPJc2e7i7dMMaI9lU,
  sentry_client_MJTLNmRW1o4nGDwwmnW8ZOFOAcwoUzwlFoet6n_LfqQ,
  sfmc_analytics_client_bVc6LCdh0pKyf0PjKliYtCKQ0TpGA0_m_H2edlrahXE
]